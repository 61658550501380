@use "~bootstrap/scss/bootstrap";

// Use Bootstrap dropdown-link-hover style for 'selected' items;
// this gives the currently-selected item a grey background.
$s2bs5-item-active-bg: bootstrap.$dropdown-link-hover-bg;

// Use Bootstrap dropdown-link-active style for 'highlighted' items;
// this gives the hovered-over items a primary color background.
$s2bs5-item-hover-bg: bootstrap.$dropdown-link-active-bg;

@import "~select2-bootstrap-5-theme/src/select2-bootstrap-5-theme";

// Insert ZERO-WIDTH SPACE in empty options
// to make sure their height does not collapse.
.select2-results__option:empty {
  &::before {
    content: "\200B";
  }
}
