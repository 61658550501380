@use "~bootstrap/scss/bootstrap-utilities" as *;

/* Mixins */
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/* Table styles */
table.fit {
  width: auto;
}

table.compact {
  font-size: 70%;
}

table.uber {
  .correct {
    background-color: lightgreen;
  }

  .wrong {
    background-color: lightpink;
  }

  .food-sales {
    background-color: #eef8ff;
  }

  .discount {
    background-color: #eeffee;
  }

  .delivery-fee {
    background-color: #ffffdd;
  }

  .delivery-cost {
    background-color: #ffeedd;
  }

  .uber-fee {
    background-color: #ffeeff;
  }
}

td {
  &.decimal {
    text-align: right;
  }

  &.total {
    font-weight: bold;
  }
}

/* Misc styles */

blockquote {
  border-left: 3px solid #61a8ef;
  padding-left: 0.5em;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25em .4em;
  font-size: 75%;
  line-height: 1;
  border-radius: .25rem;
}

.fas {
  width: 1.25em;
  text-align: center;
}

/* Login form */
div.login-wrapper {
  @include box-shadow(3px, 3px, 5px, #00000088);
  @include border-radius(.5rem);
  background-color: #fff;

}

// Overrides for django-crispy-forms:bootstrap4

.requiredField {
  font-weight: 600;
}

.asteriskField {
  display: none;
}

/**
 * Formatted currency amounts, quantities.
 */

.currency {
  display: inline-block;
  position: relative;
  padding-left: 0.75em;
  /*text-align: right;*/
  white-space: nowrap;
  width: 100%;
}

.currency .amount::before {
  position: absolute;
  left: 0;
  content: '€';
}

.quantity {
  display: inline-block;
  position: relative;
  padding-right: 0.5em;
  /*text-align: right;*/
  white-space: nowrap;
  /*width: 100%;*/
}

.quantity .amount::before {
  position: absolute;
  right: 0;
  content: 'x';
}
